<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <!-- Section Header Starts -->
    <section class="section-header">
      <HelloWorld msg="" />
      <div class="container">
        <div class="row">
          <div class="col bmsheadingleft">
            <h1 class="bmsh1">
              Grow Your Daily R.O.I <br />
              In Real Time
            </h1>
            <br />
            <p>
              Buy shares in battallions trades and get daily dividends from the
              profits of the company. Investing is simple and affordable for
              everyone!
            </p>
            <br />
            <button type="button" class="btn btmbtn">Get Started</button>
            <br />
          </div>
          <div class="col">
            <div
              class="offset-xl-1 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
            >
              <div class="circle-1"></div>
              <div
                id="carouselExampleSlidesOnly"
                class="carousel slide battallions-slides"
                data-ride="carousel"
                data-interval="3000"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="../assets/img/bg/wallet.png"
                      height="500"
                      alt="Battallions slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="../assets/img/bg/buy-shares.png"
                      height="500"
                      alt="Battallions slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="../assets/img/bg/p2p.png"
                      height="500"
                      alt="Battallions slide"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section Header Ends -->

    <section class="section_2">
      <div class="head-brief">
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <img src="../assets/favicon.png" height="25" /> &nbsp; Buy, grow &
              manage your Battallions shares in real time.
            </div>
            <div class="col-sm">
              <img src="../assets/favicon.png" height="25" /> &nbsp; Enjoy
              ultra-fast and secured Peer To Peer Trading system with full
              functionalities.
            </div>
            <div class="col-sm">
              <img src="../assets/favicon.png" height="25" /> &nbsp; Multiply
              your residual income with Battallions refferal system and exciting
              prices.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-3">
      <div class="container">
        <div class="row">
          <div class="col bmsheadingleft">
            <iframe
              src="https://www.youtube.com/embed/eu1eGuYp9yg?modestbranding=1&rel=0&color=white&controls=0"
              width="560"
              height="315"
              title="INTRODUCTION TO BATTALLIONS"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col bmsheadingleft">
            <h6 class="small-h6">ABOUT B.M.S</h6>
            <h1 class="bmsh1">Battallions Market And Share</h1>
            <p>
              BATTALLIONS is a new generation of Stocks (company shares) in
              which dividends are regularly paid to investors (shareholders)
              from its daily sales. Battallions shares are also called
              GRATITUDES, which is represented as 'G'.
            </p>
            <p>
              Just as every genuine business has its products, Battallions
              investment itself also has its product which is its shares that
              are being bought regularly through the website. Battallions shares
              are also called GRATITUDES, which is represented as 'G'.
            </p>
            <button type="button" class="btn btmbtn">Learn More</button>
          </div>
        </div>
      </div>
    </section>

    <section class="section-4 p-0 m-0">
      <div class="container work-process pb-5 pt-5">
        <div class="title mb-5 text-center sect-4-head">
          <h6 class="how-h6">// How Does Battallions Market Works? //</h6>
          <h2 class="sect-4-head">
            How <span class="site-color">It Works</span>
          </h2>
        </div>
        <!-- ============ step 1 =========== -->
        <div class="row">
          <div class="col-md-5">
            <div
              class="process-box process-left"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="process-step">
                    <p class="m-0 p-0">Step</p>
                    <h2 class="m-0 p-0">01</h2>
                  </div>
                </div>
                <div class="col-md-7">
                  <p>
                    <small
                      >BMS (the brand behind Battallions Investment), issues an
                      unlimited amount of Battallions shares to the public which
                      will be purchased through the official website only at
                      www.battallions.com
                    </small>
                  </p>
                </div>
              </div>
              <div class="process-line-l"></div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <div class="process-point-right"></div>
          </div>
        </div>
        <!-- ============ step 2 =========== -->
        <div class="row">
          <div class="col-md-5">
            <div class="process-point-left"></div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <div
              class="process-box process-right"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="process-step">
                    <p class="m-0 p-0">Step</p>
                    <h2 class="m-0 p-0">02</h2>
                  </div>
                </div>
                <div class="col-md-7">
                  <p>
                    <small
                      >Users register accounts on the website to purchase
                      Battallions shares and automatically become shareholders.
                    </small>
                  </p>
                </div>
              </div>
              <div class="process-line-r"></div>
            </div>
          </div>
        </div>
        <!-- ============ step 3 =========== -->
        <div class="row">
          <div class="col-md-5">
            <div
              class="process-box process-left"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="process-step">
                    <p class="m-0 p-0">Step</p>
                    <h2 class="m-0 p-0">03</h2>
                  </div>
                </div>
                <div class="col-md-7">
                  <p>
                    <small
                      >Part of the money generated from the total sales of
                      Battallions shares each day will be totaled and divided by
                      the total amount of active shares in the system.</small
                    >
                  </p>
                </div>
              </div>
              <div class="process-line-l"></div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <div class="process-point-right"></div>
          </div>
        </div>
        <!-- ============ step 4 =========== -->
        <div class="row">
          <div class="col-md-5">
            <div class="process-point-left"></div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <div
              class="process-box process-right"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="process-step">
                    <p class="m-0 p-0">Step</p>
                    <h2 class="m-0 p-0">04</h2>
                  </div>
                </div>
                <div class="col-md-7">
                  <p>
                    <small
                      >The GENERAL DIVIDEND for that particular day will be
                      multiplied by each active share in the system, therefore
                      every user (shareholders) will be paid by the end of the
                      day.
                    </small>
                  </p>
                </div>
              </div>
              <div class="process-line-r"></div>
            </div>
          </div>
        </div>
        <!-- ============ step 3 =========== -->
        <div class="row">
          <div class="col-md-5">
            <div
              class="process-box process-left"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div class="row">
                <div class="col-md-5">
                  <div class="process-step">
                    <h2 class="m-0 p-0">NOTE</h2>
                  </div>
                </div>
                <div class="col-md-7">
                  <p>
                    <small
                      >All these processes will be happening simultaneously as
                      long as the company makes income from selling Battallions
                      shares daily.</small
                    >
                  </p>
                </div>
              </div>
              <div class="process-line-l"></div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <div class="process-point-right process-last"></div>
          </div>
        </div>
        <!-- ============ -->
      </div>
      <component :is="'script'"> AOS.init();</component>
    </section>

    <section class="bg-silver">
      <div class="container">
        <h6 class="features-h6">
          // Great tools and features to help you grow your financial stability
          //
        </h6>
        <h1 class="features-heading">B.M.S FEATURES</h1>
        <div class="row features-row">
          <div class="col-sm features-div">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                  <div class="holderCircle">
                    <div class="round"></div>
                    <div class="dotCircle">
                      <span class="itemDot active itemDot1" data-tab="1">
                        <p>
                          Managing <br />
                          Shares
                        </p>
                        <span class="forActive"></span>
                      </span>
                      <span class="itemDot itemDot2" data-tab="2">
                        <p>P2P <br />Trading</p>
                        <span class="forActive"></span>
                      </span>
                      <span class="itemDot itemDot3" data-tab="3">
                        <p>
                          Battallions <br />
                          Dividend
                        </p>
                        <span class="forActive"></span>
                      </span>
                      <span class="itemDot itemDot4" data-tab="4">
                        <p>
                          Wallet <br />
                          System
                        </p>
                        <span class="forActive"></span>
                      </span>
                    </div>
                    <div class="contentCircle">
                      <div class="CirItem title-box active CirItem1">
                        <h3 class="title"><span>Shares Management</span></h3>
                        <p>
                          Buy shares for as low as ₦1,000 and get daily
                          dividends from the profits of Battallions Market and
                          shares. <br />
                          <br />
                          <button type="button" class="btn btmbtn">
                            Learn More
                          </button>
                        </p>
                      </div>
                      <div class="CirItem title-box CirItem2">
                        <h2 class="title">
                          <span>Battallions P2P Market </span>
                        </h2>
                        <p>
                          Battallions P2P market is where the buying and selling
                          of all Battallions shares takes place. <br />
                          <br />
                          <button type="button" class="btn btmbtn">
                            Learn More
                          </button>
                        </p>
                      </div>
                      <div class="CirItem title-box CirItem3">
                        <h2 class="title">
                          <span>Battallions Dividends</span>
                        </h2>
                        <p>
                          Dividends are income paid to shareholders from the
                          sales the company makes daily. <br />
                          <br />
                          <button type="button" class="btn btmbtn">
                            Learn More
                          </button>
                        </p>
                      </div>
                      <div class="CirItem title-box CirItem4">
                        <h2 class="title"><span>Wallet Management</span></h2>
                        <p>
                          Enjoy easy funding and withdrawal with your
                          Battallions local wallet. <br />
                          <br />
                          <button type="button" class="btn btmbtn">
                            Learn More
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="battallions-shares-feature"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <img
                  src="../assets/favicon.png"
                  height="400"
                  alt="Battallions slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq">
      <!-- Accordion -->
      <div class="container-fluid bg-gray" id="accordion-style-1">
        <div class="container">
          <section>
            <div class="row">
              <div class="col-12">
                <h1 class="text-green mb-4 text-center">
                  Frequently Asked Questions
                </h1>
              </div>
              <div class="col-10 mx-auto">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <i class="fa fa-question-circle main"></i
                          ><i class="fa fa-angle-double-right mr-3"></i>What is
                          Battallions investment?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show fade"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        BATTALLIONS is a new generation of Stocks (company
                        shares) in which dividends are regularly paid to
                        investors (shareholders) from its daily sales.
                        Battallions shares are also called GRATITUDES, which is
                        represented as 'G'.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <i class="fa fa-question-circle main"></i
                          ><i class="fa fa-angle-double-right mr-3"></i>What are
                          Battallions Market and Shares?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse fade"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Battallions Markets and Shares (BMS) is the brand behind
                        Battallions investment, it is also the brand that
                        invented the working system. The company is registered
                        with RC Number 1804074 as BTLLIONS MARKETS LIMITED. BMS
                        will also add more businesses as time goes on, the
                        company aims to also create more income-earning
                        opportunities for all its clients.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <i class="fa fa-question-circle main"></i
                          ><i class="fa fa-angle-double-right mr-3"></i>What are
                          Battallions Dividends
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse fade"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Dividends are income paid to shareholders from the sales
                        the company makes daily. Like other low-risk businesses,
                        Battallions investments do experience market
                        fluctuations, which are linked to demand and supply.
                        Demand and Supply can increase or decrease the GENERAL
                        DIVIDEND paid to investors daily, these conditions can
                        be regulated by the system admin only.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <i class="fa fa-question-circle main"></i
                          ><i class="fa fa-angle-double-right mr-3"></i>How can
                          I sell my Battallions shares in the BMS website
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse fade"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        The only way to sell Battallions shares is through the
                        markets. To sell shares in the BMS p2p market, users
                        should go to their dashboard and click on the p2p market
                        icon in the menu, a button will be displayed below for
                        users to click on, to sell their shares. After clicking
                        on the button, it will take them directly to a page
                        where they will see a disclaimer and they will be asked
                        to create an ad. They are expected to fill in the
                        necessary details and then proceed to create their ad.
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-link collapsed btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <i class="fa fa-question-circle main"></i
                          ><i class="fa fa-angle-double-right mr-3"></i>How to
                          earn regular income with Battallions investment
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse fade"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Battallions investment is a very broad business and
                        there are several ways to earn a regular income with it.
                        Three ways of earning regularly are:
                        <ul>
                          <li>Issuing of Loans</li>
                          <li>Trading Battallions Share</li>
                          <li>Earning of Dividends</li>
                        </ul>
                        Other ways to earn income in Battallions shares are;
                        growth appreciation, selling accounts, making and
                        selling courses about Battallions investments, etc.
                        Users can also create their own strategic plan, as there
                        are tons of ways to earn regular income in the
                        Battallions investment.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>

    <section class="sectfr">
      <div class="row divivi">
        <div class="col-md-6 div-l">
          <div
            class="carousel-img"
            style="background-image: url(../assets/favicon.png)"
          >
            <h2 class="carousel-title">
              Battallions <br />
              Market and Share
            </h2>
          </div>
        </div>
        <div class="col-md-6 div-r">
          <h3>Put Your Money To Work</h3>
          <hr />
          <p>
            Multiply your residual income with Battallions Market and Shares
          </p>
          <button class="btn btn-primary">Get Started</button>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <section id="footer">
      <div class="container">
        <div class="row text-center text-xs-center text-sm-left text-md-left">
          <div class="col-xs-12 col-sm-4 col-md-4">
            <ul class="list-unstyled quick-links">
              <li><img src="../assets/img/logo.png" height="50px" /></li>
              <li>
                <a>
                  Battallions Market and Share is a new generation of Stocks
                  (company shares) in which dividends are regularly paid to
                  investors (shareholders) from its daily sales.</a
                >
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <h5>Quick Links</h5>
            <ul class="list-unstyled quick-links">
              <li>
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-angle-double-right"></i>About us</a
                >
              </li>
              <li>
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-angle-double-right"></i>Contact us
                </a>
              </li>
              <li>
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-angle-double-right"></i>Privacy Policy</a
                >
              </li>
              <li>
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-angle-double-right"></i>Terms and
                  Condition</a
                >
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <h5>Let's Connect</h5>

            <ul class="list-unstyled quick-links social text-center">
              <li class="list-inline-item">
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.fiverr.com/share/qb8D02"
                  ><i class="fa fa-google-plus"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.fiverr.com/share/qb8D02" target="_blank"
                  ><i class="fa fa-envelope"></i
                ></a>
              </li>
              <li>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white"
                >
                  <p>Battallions Market and Share.</p>
                  <p>© 2023 All right Reversed.</p>
                </div>
                <hr />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- ./Footer -->

    <component :is="'script'">
      let i=2; $(document).ready(function(){ var radius = 200; var fields =
      $('.itemDot'); var container = $('.dotCircle'); var width =
      container.width(); radius = width/2.5; var height = container.height();
      var angle = 0, step = (2*Math.PI) / fields.length; fields.each(function()
      { var x = Math.round(width/2 + radius * Math.cos(angle) -
      $(this).width()/2); var y = Math.round(height/2 + radius * Math.sin(angle)
      - $(this).height()/2); if(window.console) { console.log($(this).text(), x,
      y); } $(this).css({ left: x + 'px', top: y + 'px' }); angle += step; });
      $('.itemDot').click(function(){ var dataTab= $(this).data("tab");
      $('.itemDot').removeClass('active'); $(this).addClass('active');
      $('.CirItem').removeClass('active'); $( '.CirItem'+
      dataTab).addClass('active'); i=dataTab; $('.dotCircle').css({
      "transform":"rotate("+(360-(i-1)*36)+"deg)", "transition":"2s" });
      $('.itemDot').css({ "transform":"rotate("+((i-1)*36)+"deg)",
      "transition":"1s" }); }); setInterval(function(){ var dataTab=
      $('.itemDot.active').data("tab"); if(dataTab>6||i>6){ dataTab=1; i=1; }
      $('.itemDot').removeClass('active');
      $('[data-tab="'+i+'"]').addClass('active');
      $('.CirItem').removeClass('active'); $( '.CirItem'+i).addClass('active');
      i++; $('.dotCircle').css({ "transform":"rotate("+(360-(i-2)*36)+"deg)",
      "transition":"2s" }); $('.itemDot').css({
      "transform":"rotate("+((i-2)*36)+"deg)", "transition":"1s" }); }, 5000);
      });
    </component>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
import "vue-lite-youtube-embed/style.css";
</script>
