
  
  <style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  .a {
                    fill: none;
                }
                
                .b {
                    clip-path: url(#a);
                }
                
                .c,
                .d {
                    fill: #f9f9fc;
                }
                
                .d {
                    opacity: 0.5;
                    isolation: isolate;
                }
  
  @import '../assets/styles/battallions3.css';
  </style>
  
  <script>
  export default {
    data() {
      return {
        appName: 'My App',
        pages: [
          { title: 'Home', path: '/' },
          { title: 'about', path: '/about' },
          { title: 'Contact', path: '/contact' },
        ],
      };
    },
  };
  </script>
  
  