<template>
  <nav class="navbar navbar-expand-lg navbar-light battallions-nav">
    <router-link to="/" class="navbar-brand"><img src="./assets/img/logo.png" width="180" height="50" class="d-inline-block align-top" alt=""> 
  </router-link>
<!-- 
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
 -->


 <!-- MOBILE MENU -->
  <button class="btn btn-primary d-block d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu">
     MENU
</button>

<div class="offcanvas offcanvas-start d-block d-md-none" tabindex="-1" id="mobile-menu">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title"><router-link to="/" class="navbar-brand"><img src="./assets/img/logo.png" width="180" height="50" class="d-inline-block align-top" alt=""> 
  </router-link></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item active">
        <a href="/"  class="nav-link" >Home <span class="sr-only">(current)</span></a>
      </li>
      <hr>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <router-link to="/p2p" class="dropdown-item">P2P Trading</router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/wallet" class="dropdown-item">Wallet System</router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/shares" class="dropdown-item">Shares Management</router-link>
        </div>
      </li>
      <hr>
      <!-- <li class="nav-item">
        <router-link to="/pricing" class="nav-link">Pricing <span class="sr-only">(current)</span></router-link>
      </li> -->
      <li class="nav-item">
        <a href="/features" class="nav-link">Features <span class="sr-only">(current)</span></a>
      </li>
      <hr>
      <li class="nav-item">
        <a href="/support" class="nav-link" >Support <span class="sr-only">(current)</span></a>
      </li>
      <hr>
    </ul>
    <a href="https://dashboard.battallions.com"><button class="btn btn-outline-success mr-sm-2"> Login </button> </a>
    <a href="https://dashboard.battallions.com/auth/signup"><button class="btn my-2 my-sm-0 battallions-nav create-account-btn">Create An Account</button> </a>
  </div>
</div>
<!-- MOBILE MENU END -->

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item active">
        <router-link to="/" class="nav-link" >Home <span class="sr-only">(current)</span></router-link>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <router-link to="/p2p" class="dropdown-item">P2P Trading</router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/wallet" class="dropdown-item">Wallet System</router-link>
        <div class="dropdown-divider"></div>
        <router-link to="/shares" class="dropdown-item">Shares Management</router-link>
        </div>
      </li>
      <!-- <li class="nav-item">
        <router-link to="/pricing" class="nav-link">Pricing <span class="sr-only">(current)</span></router-link>
      </li> -->
      <li class="nav-item">
        <router-link to="/features" class="nav-link">Features <span class="sr-only">(current)</span></router-link>
      </li>
      <li class="nav-item">
        <router-link to="/support" class="nav-link" >Support <span class="sr-only">(current)</span></router-link>
      </li>
    </ul>
    <a href="https://dashboard.battallions.com"><button class="btn btn-outline-success mr-sm-2"> Login </button> </a>
    <a href="https://dashboard.battallions.com/auth/signup"><button class="btn my-2 my-sm-0 battallions-nav create-account-btn">Create An Account</button> </a>

  </div>
</nav>
  <router-view/>

</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.offcanvas {
  width: 100%;
  height: 100%;
}

.navbar-toggler-icon{
  color:#fff;
  font-weight: bold;
}


@import './assets/styles/battallions.css';
@import './assets/styles/battallions2.css';
</style>

<script>
export default {
  data() {
    return {
      appName: 'My App',
      pages: [
        { title: 'Home', path: '/' },
        { title: 'about', path: '/about' },
        { title: 'Contact', path: '/contact' },
      ],
    };
  },
};

const links = document.querySelectorAll('#mobile-menu router-link');

  links.forEach(link => {
    link.addEventListener('click', () => {
      const mobileMenu = document.querySelector('#mobile-menu');
      // eslint-disable-next-line
      const offcanvas = bootstrap.Offcanvas.getInstance(mobileMenu);
      offcanvas.hide();
    });
  });
</script>

