<!-- eslint-disable no-undef -->
<!-- eslint-disable no-undef -->
<template>
  <header class="masthead">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 py-5">
          <h1 class="mb-4 mt-3">FEATURES</h1>
          <h2 class="m-0">Features of Battallions Shares (GRATITUDES)</h2>
        </div>
      </div>
    </div>
    <svg
      style="pointer-events: none"
      class="wave"
      width="100%"
      height="50px"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 75"
    >
      <defs>
        <clipPath id="a">
          <rect class="a" width="1920" height="75"></rect>
        </clipPath>
      </defs>
      <title>wave</title>
      <g class="b">
        <path
          class="c"
          d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"
        ></path>
      </g>
    </svg>
  </header>
  <HeaderView />

  <section>
    <div class="bg-white border rounded-5">
      <section class="p-4">
        <!--Section heading-->
        <h2 class="h1-responsive py-5 font-weight-bold text-center">
          Battallions Features
        </h2>
        <!--Section description-->
        <p class="px-5 mb-5 pb-3 lead grey-text text-center">
          FEATURES OF BATTALLIONS SHARES (GRATITUDES)
        </p>

        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-4">
            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-flag-checkered deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">Battallions shares are tradable.</p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-flask deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares have a value that is derived from their
                  flexible features and daily dividends.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-glass-martini deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Prices of battallions shares are stable.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-glass-martini deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares are also programmed to be used as
                  collaterals.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-glass-martini deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares can also be rewarded as a token of
                  gratitude to business customers, clients, employees, partners,
                  family, and friends.
                </p>
              </div>
            </div>
            <!--Grid row-->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-4 mb-2 center-on-small-only flex-center">
            <img
              src="../assets/img/bg/wallet.png"
              alt=""
              height="500px"
              class="z-depth-0"
            />
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-4">
            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fsa fa-2x fa-heart deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares are always rewarded with dividends daily as
                  long as the company makes sales from selling shares.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-bolt deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares have unlimited amounts of supply, this
                  means that a Battallions investment company can never stop
                  issuing shares to the public.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-bolt deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares are divisible, this means that they can be
                  divided into smaller units.
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-bolt deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">
                  Battallions shares can be in a physical form or virtual form
                  (online).
                </p>
              </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-2">
              <div class="col-2">
                <i class="fas fa-2x fa-magic deep-purple-text"></i>
              </div>
              <div class="col-10 text-left">
                <img src="../assets/favicon.png" height="25" />
                <p class="grey-text">Battallions shares are transferable.</p>
              </div>
            </div>
            <!--Grid row-->
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </section>
    </div>
  </section>

  <FooterView />
</template>

<script>
import HeaderView from "@/views/HeaderView.vue";
import FooterView from "@/views/FooterView.vue";

export default {
  name: "P2PView",
  components: {
    HeaderView,
    FooterView,
  },
};
// eslint-disable-next-line no-unused-vars, no-undef
$(document).ready(function () {
  var clickEvent = false;
  // eslint-disable-next-line no-undef
  $("#myCarousel")
    .on("click", ".nav a", function () {
      clickEvent = true;
      // eslint-disable-next-line no-undef
      $(this).parent().siblings().removeClass("active");
      // eslint-disable-next-line no-undef
      $(this).parent().addClass("active");
    })
    .on("slid.bs.carousel", function (e) {
      if (!clickEvent) {
        // eslint-disable-next-line no-undef
        itemIndex = $(e.relatedTarget).index();
        // eslint-disable-next-line no-undef
        targetNavItem = $(".nav li[data-slide-to='" + itemIndex + "']");
        // eslint-disable-next-line no-undef
        $(".nav li").not(targetNavItem).removeClass("active");
        // eslint-disable-next-line no-undef
        targetNavItem.addClass("active");
      }
      clickEvent = false;
    });
});
</script>

<style>
/* Card Styles */

.card-sl {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 200px;
}

.card-image img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px 8px 0px 0;
}

.card-action {
  position: relative;
  float: right;
  margin-top: -25px;
  margin-right: 20px;
  z-index: 2;
  color: #007b5e;
  background: #fff;
  border-radius: 100%;
  padding: 15px;
  font-size: 15px;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

.card-action:hover {
  color: #fff;
  background: #089b79;
  -webkit-animation: pulse 1.5s infinite;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
  background: #fff;
  padding: 10px 15px;
}

.card-text {
  padding: 10px 15px;
  background: #fff;
  font-size: 16px;
  color: #151515;
}

.card-button {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  background-color: #1f487e;
  color: #fff;
  border-radius: 0 0 8px 8px;
}

.card-button:hover {
  text-decoration: none;
  background-color: #1d3461;
  color: #fff;
}

@-webkit-keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }

  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.carousel {
  margin: 30px 0;
  background: #ccc;
}
.carousel .carousel-item {
  text-align: center;
  overflow: hidden;
  height: 475px;
}
.carousel .carousel-item img {
  max-width: 100%;
  margin: 0 auto; /* Align slide image horizontally center in Bootstrap v3 */
}
.carousel-caption h3,
.carousel-caption p {
  color: #fff;
  display: inline-block;
  font-family: "Oswald", sans-serif;
  text-shadow: none;
  margin-bottom: 20px;
}
.carousel-caption h3 {
  background: rgba(0, 0, 0, 0.9);
  padding: 12px 24px;
  font-size: 40px;
  text-transform: uppercase;
}
.carousel-caption p {
  background: #20b0b9;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 300;
}
.carousel .nav {
  background: #eee;
}
.carousel .nav a {
  color: #333;
  border-radius: 0;
  font-size: 85%;
  padding: 10px 16px;
  background: transparent;
}
.carousel .nav .active a {
  color: #fff;
  background: #20b0b9;
}
.carousel .nav strong {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 110%;
  text-transform: uppercase;
}
</style>
