<!-- eslint-disable no-undef -->
<!-- eslint-disable no-undef -->
<template>
  <header class="masthead">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 py-5">
          <h1 class="mb-4 mt-4">We'd love to hear from you!</h1>
          <h2 class="m-0">Battallions Support</h2>
        </div>
      </div>
    </div>
    <svg
      style="pointer-events: none"
      class="wave"
      width="100%"
      height="50px"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 75"
    >
      <defs>
        <clipPath id="a">
          <rect class="a" width="1920" height="75"></rect>
        </clipPath>
      </defs>
      <title>wave</title>
      <g class="b">
        <path
          class="c"
          d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"
        ></path>
      </g>
      <g class="b">
        <path
          class="d"
          d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"
        ></path>
      </g>
    </svg>
  </header>
  <HeaderView />
  <div class="support-page">
    <div class="container contact">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-4">
            <h4>CONTACT DETAILS</h4>
            <div class="thisthis">
              <div class="contact-info">
                <p>
                  <img
                    src="https://image.ibb.co/kUASdV/contact-image.png"
                    alt="image"
                    style="font-size: 20px"
                  />
                </p>
                <p>
                  EMAIL US:
                  <a href="mailto:support@battallions.com" style="color: white"
                    >support@battallions.com</a
                  >
                </p>

                <p>
                  <i
                    class="fa fa-phone"
                    aria-hidden="true"
                    style="font-size: 65px"
                  ></i>
                </p>
                <p>CALL US: +234-000 000 00</p>

                <p style="margin-top: 55px">OPENING HOURS:</p>
                <p>Mon to Friday 8am - 4:30pm</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="contact-form">
              <h2 class="mb-4">Write us a message</h2>
              <div class="form-group" style="text-align: left">
                <label class="control-label" for="fname">First Name:</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="fname"
                    placeholder="Enter First Name"
                    name="fname"
                  />
                </div>
              </div>
              <div class="form-group" style="text-align: left">
                <label class="control-label" for="lname">Last Name:</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="lname"
                    placeholder="Enter Last Name"
                    name="lname"
                  />
                </div>
              </div>
              <div class="form-group" style="text-align: left">
                <label class="control-label" for="email">Email:</label>
                <div class="col-sm-10">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                  />
                </div>
              </div>
              <div class="form-group" style="text-align: left">
                <label class="control-label" for="comment">Comment:</label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    rows="5"
                    id="comment"
                  ></textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <button type="submit" class="btn btn-default">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterView />
</template>

<script>
import HeaderView from "@/views/HeaderView.vue";
import FooterView from "@/views/FooterView.vue";

export default {
  name: "P2PView",
  components: {
    HeaderView,
    FooterView,
  },
};
// eslint-disable-next-line no-unused-vars, no-undef
$(document).ready(function () {
  var clickEvent = false;
  // eslint-disable-next-line no-undef
  $("#myCarousel")
    .on("click", ".nav a", function () {
      clickEvent = true;
      // eslint-disable-next-line no-undef
      $(this).parent().siblings().removeClass("active");
      // eslint-disable-next-line no-undef
      $(this).parent().addClass("active");
    })
    .on("slid.bs.carousel", function (e) {
      if (!clickEvent) {
        // eslint-disable-next-line no-undef
        itemIndex = $(e.relatedTarget).index();
        // eslint-disable-next-line no-undef
        targetNavItem = $(".nav li[data-slide-to='" + itemIndex + "']");
        // eslint-disable-next-line no-undef
        $(".nav li").not(targetNavItem).removeClass("active");
        // eslint-disable-next-line no-undef
        targetNavItem.addClass("active");
      }
      clickEvent = false;
    });
});
</script>

<style>
/* Card Styles */

.support-page {
  margin-top: 40px;
}
.contact-info {
  margin-top: 25px;
}
.thisthis {
  margin-top: 20px;
  color: #fff;
  background: #007b5e;
  padding: 10px;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.contact-form {
  margin-left: 37px;
}
.contact-form label {
  font-weight: 600;
}
.contact-form button {
  background: #007b5e;
  color: #fff;
  font-weight: 600;
  width: 25%;
}
.contact-form button:hover {
  background: #007b5e;
  color: #fff;
}

.contact-form button:focus {
  box-shadow: none;
}

label {
  text-align: left !important;
}
</style>
