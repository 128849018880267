<template>
 
<div class="container-fluid">
	<div class="row">
	<div class="col-xs-12 col-md-6">
	<div class="bg bg-left">
    
    <h2 style="padding-top:80px; font-weight: bold;">Welcome</h2>
    <img src="../../assets/img/login.png" style="padding-top:50px" height="500" alt="">
	
	</div>
	</div>
 <div class="col-xs-12 col-md-6">
            <div class="bg bg-right">
              

  <img src="../../assets/img/logo.png" height="80" alt="" style="margin-top: 50px;"> <br>
  <p style="font-weight: bold;">Login to your Battalion Trade Account</p>

  <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address">
      </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
  </div>
  <div class="form-group form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Remember me</label>
  </div>
  <div   style="font-weight: 800; text-align: left; margin: 30px 0px;">
<router-link to="">Forgot Password? </router-link>
</div>
  <button type="submit" class="btn btn-primary btn-lg btn-block">Sign in</button>
  <small> New to battalion? <router-link to="" style="font-weight: bold;"> Create an account </router-link></small>
</form>

 </div>
	</div>
	</div>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pageTitle: 'Battallions Login',
      };
    },
  };
  </script>
  <style>
  a{
    color: #007b5e;
  }
.bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
    height: 600px;
    padding: 0px !important;
    margin: 0px !important;
}
.row>*{
  padding: 0px !important;
}
.col {
    padding-left:0px;
    padding-right: 0px;
}
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}
.bg-left {
    background: #c2c1c1;
}

.bg-right {
  background: #ffffff;
}

.form-group{
  text-align: left !important;
}

form{
  padding-top: 30px;
margin-right: 100px;
margin-left: 100px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 0px;
    padding-right: 0px;
}
</style>