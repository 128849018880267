<template>
 <header class="masthead">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 py-5">
                <h1 class="mb-4">P2P Trading</h1>
                <h2 class="m-0">Battallions Peer To Peer Trading</h2>
            </div>

        </div>
    </div>
    <svg style="pointer-events: none" class="wave" width="100%" height="50px" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 75">
        <defs>

            <clipPath id="a">
                <rect class="a" width="1920" height="75"></rect>
            </clipPath>
        </defs>
        <title>wave</title>
        <g class="b">
            <path class="c" d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"></path>
        </g>
        <g class="b">
            <path class="d" d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"></path>
        </g>
        <g class="b">
            <path class="d" d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"></path>
        </g>
        <g class="b">
            <path class="d" d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"></path>
        </g>
    </svg>
</header>
      <HeaderView />


<section class="section-3">

<div class="container">
<div class="row">
  <div class="col bmsheadingleft">
    <h6 class="small-h6">Battallions Peer To Peer Trading </h6>
   <h1 class="bmsh1">Battallions P2P Market </h1>
   <p>Battallions P2P market is where the buying and selling of all Battallions
shares takes place, the P2P market gives users the ability to purchase
Battallions shares in large quantities and it also create financial and job
opportunities for merchants, who buys Battallions shares from the company
and sell them at higher prices.</p>
<a href="https://dashboard.battallions.com"><button type="button" class="btn btmbtn">START TRADING</button> </a>
  </div>
  <div class="col bmsheadingleft">
<iframe src="https://www.youtube.com/embed/eu1eGuYp9yg?modestbranding=1&rel=0&color=white&controls=0" width="400" height="315" title="INTRODUCTION TO BATTALLIONS" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
</div>
</section>


<!-- HOW P2P WORKS -->
<section class="section-4 p-0 m-0">
    <div class="container work-process  pb-5 pt-5">
        <div class="title mb-5 text-center sect-4-head">
          <h6 class="how-h6"> // How Does Battallions P2P Market Works? //</h6>
        <h2 class="sect-4-head">HOW <span class="site-color">THE P2P MARKET WORKS</span></h2>
        
    </div>
        <!-- ============ step 1 =========== -->
        <div class="row">
            <div class="col-md-5">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">01</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <p><small>To sell Battallions shares, the user or merchant (Share holder) should
click on the P2P market icon and click on the button to sell shares,
where he or she will be required to create an ad.</small></p>
                        </div>
                    </div>
                    <div class="process-line-l"></div>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
                <div class="process-point-right"></div>
            </div>
        </div>
        <!-- ============ step 2 =========== -->
        <div class="row">
            
            <div class="col-md-5 d-block d-none d-lg-block">
                <div class="process-point-left"></div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
                <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">02</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <p><small>He or she must meet certain requirements before the ad will be approved.</small></p>
                        </div>
                    </div>
                    <div class="process-line-r"></div>
                </div>
            </div>

        </div>
        <!-- ============ step 3 =========== -->
        <div class="row">
            <div class="col-md-5">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">03</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <p><small>After the ad has been approved, it will be shown in the P2P market for buyers to buy.</small></p>
                        </div>
                    </div>
                    <div class="process-line-l"></div>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
                <div class="process-point-right"></div>
            </div>
        </div>
        <!-- ============ step 4 =========== -->
        <div class="row">
            <div class="col-md-5 d-none d-lg-block">
                <div class="process-point-left"></div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
                <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                                <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">04</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <p><small>Buyers can click on the ad, fill the required details and proceed to buy shares.</small></p>
                        </div>
                    </div>
                    <div class="process-line-r"></div>
                </div>
            </div>
            
            
        </div>
        <!-- ============ step 3 =========== -->
        <div class="row">
            <div class="col-md-5">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="process-step">
                              <p class="m-0 p-0">Step</p>
                                <h2 class="m-0 p-0">05</h2>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <p><small>By clicking on the 'buy' button, the shares in the ad will be automatically
exchanged with the money in the buyer's wallet instantly.
These processes can happen repeatedly until the shares in the ad have
been exhausted.</small></p>
                        </div>
                    </div>
                    <div class="process-line-l"></div>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
                <div class="process-point-right process-last"></div>
            </div>
        </div>
        <!-- ============ -->
    </div>
    <component :is="'script'">
      AOS.init();</component>
</section>


<section style="margin: 50px 0px">

  <div class="container">
  <div class="row">
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%; min-height: 200px !important;">
                <div class="card-sl">
                    <span class="card-action">1</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      There is no maximum limit to the number of shares one can buy or sell in it daily.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">2</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      It is 100% safe because there are no interactions between buyers and sellers,
as the system controls and regulates transactions.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">3</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      It allows users and merchants to earn income while trading.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>

    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">4</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      The p2p market feature provides job opportunities to all Battallions merchants
in which they could be earning a regular income from trading Battallions shares
and more.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
  </div>
</div>

<div class="container" style="margin-top:50px; margin-bottom: 50px;">
  <div class="row">
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%; min-height: 200px !important;">
                <div class="card-sl">
                    <span class="card-action">5</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      Merchants can determine their profits by setting their preferred prices when
creating ads.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">6</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      The p2p market allows merchants to form associations that can determine
the prices and volumes in which Battallions shares are traded in the P2P market.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">7</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      Merchant corporations can also be formed to achieve certain goals and
targets.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>

    <div class="col-sm">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%">
                <div class="card-sl">
                    <span class="card-action">8</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      The p2p market can be integrated with any third-party platform for control and
decentralization.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>
  </div>
</div>
<div class="col-sm" style="margin-bottom: 100px;">
      <div class="container">
        <div class="row">
            <div class="col-md-3" style="width:100%; max-height: 20px !important;">
                <div class="card-sl">
                    <span class="card-action">9</span>
                    <div class="card-heading">
                       
                    </div>
                    <div class="card-text">
                      Merchants may not need to be online while their shares are trading
automatically.
                    </div>
                </div>
            </div>
        </div> 
        </div> 
    </div>




        
</section>

      <FooterView />
    
  </template>
  
  <script>
import HeaderView from '@/views/HeaderView.vue'
import FooterView from '@/views/FooterView.vue'

export default {
  name: 'P2PView',
  components: {
    HeaderView,
    FooterView
  }
};
  </script>

  <style>
  /* Card Styles */

  .card-sl {
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        min-height: 200px;
    }

    .card-image img {
        max-height: 100%;
        max-width: 100%;
        border-radius: 8px 8px 0px 0;
    }

    .card-action {
        position: relative;
        float: right;
        margin-top: -25px;
        margin-right: 20px;
        z-index: 2;
        color: #007b5e;
        background: #fff;
        border-radius: 100%;
        padding: 15px;
        font-size: 15px;
        font-weight: bold;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    }

    .card-action:hover {
        color: #fff;
        background: #089b79;
        -webkit-animation: pulse 1.5s infinite;
    }

    .card-heading {
        font-size: 18px;
        font-weight: bold;
        background: #fff;
        padding: 10px 15px;
    }

    .card-text {
        padding: 10px 15px;
        background: #fff;
        font-size: 16px;
        color: #151515;
    }

    .card-button {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        width: 100%;
        background-color: #1F487E;
        color: #fff;
        border-radius: 0 0 8px 8px;
    }

    .card-button:hover {
        text-decoration: none;
        background-color: #1D3461;
        color: #fff;

    }

    @-webkit-keyframes pulse {
        0% {
            -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
        }

        70% {
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -webkit-transform: scale(1);
            transform: scale(1);
            box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
        }

        100% {
            -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
            -webkit-transform: scale(0.9);
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
        }
    }
    .masthead{
        height:250px
    }
</style>
  