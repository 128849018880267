<template>
    <div class="container">
      <h1>{{ pageTitle }}</h1>
      <h1>Contact Battallions Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pageTitle: 'Contact Battallions',
      };
    },
  };
  </script>
  