<template>
  <header class="masthead">
     <div class="container">
         <div class="row align-items-center">
             <div class="col-lg-7 py-5">
                 <h1 class="mb-4">Battallions Dividends</h1>
                 <h2 class="m-0">What is Battallions Dividends</h2>
             </div>
 
         </div>
     </div>
     <svg style="pointer-events: none" class="wave" width="100%" height="50px" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 75">
         <defs>
 
             <clipPath id="a">
                 <rect class="a" width="1920" height="75"></rect>
             </clipPath>
         </defs>
         <title>wave</title>
         <g class="b">
             <path class="c" d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"></path>
         </g>
         <g class="b">
             <path class="d" d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"></path>
         </g>
         <g class="b">
             <path class="d" d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"></path>
         </g>
         <g class="b">
             <path class="d" d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"></path>
         </g>
     </svg>
 </header>
       <HeaderView />
 
 
 <section class="section-3">
 
 <div class="container">
 <div class="row">
   <div class="col bmsheadingleft">
     <h6 class="small-h6">Dividends Management </h6>
    <h1 class="bmsh1">Battallions Dividends </h1>
    <p>Dividends are income paid to shareholders from the sales the company
makes daily. Like other low-risk businesses, Battallions investments do
experience market fluctuations, which are linked to demand and supply.
Demand and Supply can increase or decrease the GENERAL DIVIDEND paid
to investors daily, these conditions can be regulated by the system admin
only.</p>
<a href="https://dashboard.battallions.com"> <button type="button" class="btn btmbtn">GET STARTED</button></a>
   </div>
   <div class="col bmsheadingleft">
 <iframe src="https://www.youtube.com/embed/eu1eGuYp9yg?modestbranding=1&rel=0&color=white&controls=0" width="560" height="315" title="INTRODUCTION TO BATTALLIONS" frameborder="0" allowfullscreen></iframe>
   </div>
 </div>
 </div>
 </section>
 
 
 <!-- HOW P2P WORKS -->
 <section class="section-4 p-0 m-0">
     <div class="container work-process  pb-5 pt-5">
         <div class="title mb-5 text-center sect-4-head">
           <h6 class="how-h6"> // How Battallions is being regulated? //</h6>
         <h2 class="sect-4-head">BATTALLIONS <span class="site-color">DIVIDENDS REGULATIONS</span></h2>
         
     </div>
         <!-- ============ step 1 =========== -->
         <div class="row">
             <div class="col-md-5">
                 <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                     <div class="row">
                         <div>
                             <p>by controlling the amount of money that is being saved from the daily sales of Battallions shares.</p>
                         </div>
                     </div>
                     <div class="process-line-l"></div>
                 </div>
             </div>
             <div class="col-md-2"></div>
             <div class="col-md-5">
                 <div class="process-point-right"></div>
             </div>
         </div>
         <!-- ============ step 2 =========== -->
         <div class="row">
             
             <div class="col-md-5">
                 <div class="process-point-left"></div>
             </div>
             <div class="col-md-2"></div>
             <div class="col-md-5">
                 <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                     <div class="row">
                         <div>
                          <div>
                             <p>by controlling the amount of money that is being saved from the daily sales of Battallions shares.</p>
                         </div>
                         </div>
                     </div>
                     <div class="process-line-r"></div>
                 </div>
             </div>
 
         </div>
         <!-- ============ step 3 =========== -->
         <div class="row">
             <div class="col-md-5">
                 <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                     <div class="row">
                          <div>
                             <p>By repurchasing a lot of Battallions shares from the P2P market, thereby making the demand for Battallions shares increase and this increases sales, then sales increase the GENERAL DIVIDEND.</p>
                         </div>
                     </div>
                     <div class="process-line-l"></div>
                 </div>
             </div>
             <div class="col-md-2"></div>
             <div class="col-md-5">
                 <div class="process-point-right"></div>
             </div>
         </div>
         <!-- ============ step 4 =========== -->
         <div class="row">
             <div class="col-md-5">
                 <div class="process-point-left"></div>
             </div>
             <div class="col-md-2"></div>
             <div class="col-md-5">
                 <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                     <div class="row">
                         <div>
                             <p>By increasing awareness, marketing, and advertising, for new and old users to increase in purchasing Battallions shares.</p>
                         </div>
                     </div>
                     <div class="process-line-r"></div>
                 </div>
             </div>
             
             
         </div>
         <!-- ============ step 3 =========== -->
         <div class="row">
             <div class="col-md-5">
                 <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                     <div class="row">
                          <div>
                             <p>By creating new ideas, plans, and structures that will boost the sales of Battallions shares effectively.</p>
                         </div>
                     </div>
                     <div class="process-line-l"></div>
                 </div>
             </div>
             <div class="col-md-2"></div>
             <div class="col-md-5">
                 <div class="process-point-right process-last"></div>
             </div>
         </div>
         <!-- ============ -->
     </div>
     <component :is="'script'">
       AOS.init();</component>
 </section>
 
 
 <section style="margin: 50px 0px">

  <h1 style="text-align:center; margin-top; 50px; margin-bottom:50px"> DIVIDENDS TERMS </h1>
 
   <div class="container">
   <div class="row">
     <div class="col-sm">
       <div class="container">
         <div class="row">
             <div class="col-md-3" style="width:100%; min-height: 200px !important;">
                 <div class="card-sl">
                     <span class="card-action">1</span>
                     <div class="card-heading">
                      Low Dividend Market
                     </div>
                     <div class="card-text">
                      This is a situation whereby the general dividend for
a particular day is significantly lower than the previous day. It is identified by
the significant decrease in the graph line as shown in the diagram. It is often
caused by low sales of Battallions shares, poor admin management, excess
Battallions shares in circulation, etc.
                     </div>
                 </div>
             </div>
         </div> 
         </div> 
     </div>
     <div class="col-sm">
       <div class="container">
         <div class="row">
             <div class="col-md-3" style="width:100%">
                 <div class="card-sl">
                     <span class="card-action">2</span>
                     <div class="card-heading">
                      High Dividend Market:
                     </div>
                     <div class="card-text">
                      This is a situation whereby the general dividend for a
particular day is significantly higher than the previous day. It is identified by
the significant increase in the graph line as shown in the diagram. It is often
caused by high sales of Battallions shares, good admin management, low
Battallions shares in circulation, etc.
                     </div>
                 </div>
             </div>
         </div> 
         </div> 
     </div>
     <div class="col-sm">
       <div class="container">
         <div class="row">
             <div class="col-md-3" style="width:100%">
                 <div class="card-sl">
                     <span class="card-action">3</span>
                     <div class="card-heading">
                      Stable Dividend Market
                     </div>
                     <div class="card-text">
                      This is a situation whereby the general dividend for
a particular day is the same as that of the previous day. It is often caused by
high sales of Battallions shares, good admin management, moderate
Battallions shares in circulation, etc.
                     </div>
                 </div>
             </div>
         </div> 
         </div> 
     </div>
 
      </div>
 </div>
 

         
 </section>
 
       <FooterView />
     
   </template>
   
   <script>
 import HeaderView from '@/views/HeaderView.vue'
 import FooterView from '@/views/FooterView.vue'
 
 export default {
   name: 'P2PView',
   components: {
     HeaderView,
     FooterView
   }
 };
   </script>
 
   <style>
   /* Card Styles */
 
   .card-sl {
         border-radius: 8px;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
         min-height: 200px;
     }
 
     .card-image img {
         max-height: 100%;
         max-width: 100%;
         border-radius: 8px 8px 0px 0;
     }
 
     .card-action {
         position: relative;
         float: right;
         margin-top: -25px;
         margin-right: 20px;
         z-index: 2;
         color: #007b5e;
         background: #fff;
         border-radius: 100%;
         padding: 15px;
         font-size: 15px;
         font-weight: bold;
         box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
     }
 
     .card-action:hover {
         color: #fff;
         background: #089b79;
         -webkit-animation: pulse 1.5s infinite;
     }
 
     .card-heading {
         font-size: 18px;
         font-weight: bold;
         background: #fff;
         padding: 10px 15px;
     }
 
     .card-text {
         padding: 10px 15px;
         background: #fff;
         font-size: 16px;
         color: #151515;
     }
 
     .card-button {
         display: flex;
         justify-content: center;
         padding: 10px 0;
         width: 100%;
         background-color: #1F487E;
         color: #fff;
         border-radius: 0 0 8px 8px;
     }
 
     .card-button:hover {
         text-decoration: none;
         background-color: #1D3461;
         color: #fff;
 
     }
 
     @-webkit-keyframes pulse {
         0% {
             -moz-transform: scale(0.9);
             -ms-transform: scale(0.9);
             -webkit-transform: scale(0.9);
             transform: scale(0.9);
         }
 
         70% {
             -moz-transform: scale(1);
             -ms-transform: scale(1);
             -webkit-transform: scale(1);
             transform: scale(1);
             box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
         }
 
         100% {
             -moz-transform: scale(0.9);
             -ms-transform: scale(0.9);
             -webkit-transform: scale(0.9);
             transform: scale(0.9);
             box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
         }
     }
 </style>
   