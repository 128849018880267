<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <!-- Footer -->
    <br />
    <section id="footer" style="margin-top: 50px">
      <div class="container">
        <div class="row text-center text-xs-center text-sm-left text-md-left">
          <div class="col-xs-12 col-sm-4 col-md-4">
            <ul class="list-unstyled quick-links">
              <li><img src="../assets/img/logo.png" height="50px" /></li>
              <li>
                <a>
                  Battallions Market and Share is a new generation of Stocks
                  (company shares) in which dividends are regularly paid to
                  investors (shareholders) from its daily sales.</a
                >
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <h5>Quick Links</h5>
            <ul class="list-unstyled quick-links">
              <li>
                <a href="#"><i class="fa fa-angle-double-right"></i>About us</a>
              </li>
              <li>
                <a href="#"
                  ><i class="fa fa-angle-double-right"></i>Contact us
                </a>
              </li>
              <li>
                <a href="#"
                  ><i class="fa fa-angle-double-right"></i>Privacy Policy</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="fa fa-angle-double-right"></i>Terms and
                  Condition</a
                >
              </li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <h5>Let's Connect</h5>

            <ul class="list-unstyled quick-links social text-center">
              <li class="list-inline-item">
                <a href="https://m.facebook.com/100085534998919/"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://twitter.com/BattallionShare?t=EiwCqz0Rcv23_-ul2NQTyg&s=09"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://instagram.com/battallionshare?igshid=YmMyMTA2M2Y="
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a href="#"><i class="fa fa-google-plus"></i></a>
              </li>
              <li class="list-inline-item">
                <a href="#" target="_blank"><i class="fa fa-envelope"></i></a>
              </li>
              <li>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white"
                >
                  <p>Battallions Market and Share.</p>
                  <p>© 2023 All right Reversed.</p>
                </div>
                <hr />
              </li>
            </ul>
          </div>
        </div>
        
      </div>
      
    </section>
    <!-- ./Footer -->

    <component :is="'script'">
      let i=2; $(document).ready(function(){ var radius = 200; var fields =
      $('.itemDot'); var container = $('.dotCircle'); var width =
      container.width(); radius = width/2.5; var height = container.height();
      var angle = 0, step = (2*Math.PI) / fields.length; fields.each(function()
      { var x = Math.round(width/2 + radius * Math.cos(angle) -
      $(this).width()/2); var y = Math.round(height/2 + radius * Math.sin(angle)
      - $(this).height()/2); if(window.console) { console.log($(this).text(), x,
      y); } $(this).css({ left: x + 'px', top: y + 'px' }); angle += step; });
      $('.itemDot').click(function(){ var dataTab= $(this).data("tab");
      $('.itemDot').removeClass('active'); $(this).addClass('active');
      $('.CirItem').removeClass('active'); $( '.CirItem'+
      dataTab).addClass('active'); i=dataTab; $('.dotCircle').css({
      "transform":"rotate("+(360-(i-1)*36)+"deg)", "transition":"2s" });
      $('.itemDot').css({ "transform":"rotate("+((i-1)*36)+"deg)",
      "transition":"1s" }); }); setInterval(function(){ var dataTab=
      $('.itemDot.active').data("tab"); if(dataTab>6||i>6){ dataTab=1; i=1; }
      $('.itemDot').removeClass('active');
      $('[data-tab="'+i+'"]').addClass('active');
      $('.CirItem').removeClass('active'); $( '.CirItem'+i).addClass('active');
      i++; $('.dotCircle').css({ "transform":"rotate("+(360-(i-2)*36)+"deg)",
      "transition":"2s" }); $('.itemDot').css({
      "transform":"rotate("+((i-2)*36)+"deg)", "transition":"1s" }); }, 5000);
      });
    </component>

    
  </div>
</template>
<script>
export default {
  mounted() {
    const script = document.createElement('script');
    script.src = '//code.tidio.co/ruc87m1q0m2z9wqhjwohm5rlhbbfi2ci.js';
    script.async = true;
    document.head.appendChild(script);
  }
};
</script>
 