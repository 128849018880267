import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import FeaturesView from '../views/FeaturesView.vue'
import PricingView from '../views/PricingView.vue'
import SupportView from '../views/SupportView.vue'
import P2PView from '../views/Products/P2PView.vue'
import ProductsView from '../views/Products/ProductsView.vue'
import SharesView from '../views/Products/SharesView.vue'
import WalletView from '../views/Products/WalletView.vue'
import LoginView from '../views/Auth/LoginView.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import ResetPassView from '../views/Auth/ResetPassView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('../views/FeaturesView.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/SupportView.vue')
  },
  {
    path: '/p2p',
    name: 'p2p',
    component: () => import('../views/Products/P2PView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products/ProductsView.vue')
  },
  {
    path: '/shares',
    name: 'shares',
    component: () => import('../views/Products/SharesView.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/Products/WalletView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Auth/RegisterView.vue')
  },
  {
    path: '/resetpass',
    name: 'resetpass',
    component: () => import('../views/Auth/ResetPassView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
